<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <div>
            <v-card>
              <v-card-title>
                <div class="flex justify-start text-sm" v-if="fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador.length > 0">
                    <div class="w-full md:w-1/5"  v-for="(item_ce, index) in fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador" :key="index">
                      <div class="pl-2">
                              <div class="grid grid-flow-row auto-rows-max">
                                  <div>
                                      {{ item_ce.criterio }} - {{ item_ce.peso_criterio }}%
                                  </div>
                                  <div>
                                      {{ fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador[index].indicador.length }} indicador(es)
                                  </div>
                                  <div>
                                      <v-progress-linear :width="15" color="success" height="8" :value="sumPesos(fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador[index].indicador)"></v-progress-linear>
                                  </div>
                                  <div>
                                      {{ sumPesos(fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador[index].indicador) }}%                                       
                                  </div>
                              </div>

                      </div>
                    </div>
                </div>
                <div class="flex justify-start text-sm" v-else>
                    <div class="w-full md:w-1/5"  v-for="(item_ce, index) in fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion" :key="index">
                      <div class="pl-2">
                              <div class="grid grid-flow-row auto-rows-max">
                                  <div>
                                      {{ item_ce.co_criterio_evaluacion }} - {{ item_ce.nu_ponderacion_criterio }}%
                                  </div>
                                  <div>
                                      0 indicador(es)
                                  </div>
                                  <div>
                                      <v-progress-linear :width="15" color="success" height="8" :value="0"></v-progress-linear>
                                  </div>
                                  <div>
                                      0%                                       
                                  </div>
                              </div>

                      </div>
                    </div>
                </div>
                <v-spacer></v-spacer>
              </v-card-title>

                <v-data-table
                  :headers="headers"
                  :items="fields_evaluacion.group_indicador_aprendizaje.json_group_indicador_aprendizaje.json_group_indicador_aprendizaje"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <template v-slot:item.json_criterio="{ item }">

                      {{ item.json_criterio.co_criterio_evaluacion }}
                  </template> 
                  <template v-slot:item.json_indicador="{ item }">
                      {{ item.json_indicador.tx_descripcion_indicador }}
                  </template>   
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Asociar indicador de aprendizaje
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.json_criterio_indicador_aprendizaje"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.json_criterio_indicador_aprendizaje"
                                                  :items="fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion"
                                                  item-text="tx_denominacion_criterio_evaluacion"
                                                  item-value="co_criterio_evaluacion"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      

                                        </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_aprendizaje.json_competencia.json_competencia"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_aprendizaje.json_competencia.json_competencia"
                                                  :items="list_competencias"
                                                  item-text="tx_competencia"
                                                  item-value="nu_id_competencia"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_aprendizaje.json_competencia.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_aprendizaje.json_competencia.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  @change="set_indicador_aprendozaje"
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      

                                        </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_aprendizaje.json_indicador.json_indicador"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_aprendizaje.json_indicador.json_indicador"
                                                  :items="list_indicador_aprendizaje"
                                                  item-text="tx_descripcion_indicador"
                                                  item-value="co_indicador"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_aprendizaje.json_indicador.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_aprendizaje.json_indicador.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      
                                        </validation-provider>                    
                                      </v-col>
<!--                                      
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_evaluacion.group_indicador_aprendizaje.co_indicador_aprendizaje.co_indicador_aprendizaje"
                                            :rules=fields_evaluacion.group_indicador_aprendizaje.co_indicador_aprendizaje.caracteristicas.required
                                          >
                                          <v-text-field
                                            :label=fields_evaluacion.group_indicador_aprendizaje.co_indicador_aprendizaje.caracteristicas.label
                                            v-model="fields_evaluacion.group_indicador_aprendizaje.co_indicador_aprendizaje.co_indicador_aprendizaje"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                          </validation-provider>     
                                            </template>
                                            <span>{{ fields_evaluacion.group_indicador_aprendizaje.co_indicador_aprendizaje.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </v-col>

                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_aprendizaje.tx_nombre_indicador_aprendizaje.tx_nombre_indicador_aprendizaje"
                                          :rules=fields_evaluacion.group_indicador_aprendizaje.tx_nombre_indicador_aprendizaje.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_evaluacion.group_indicador_aprendizaje.tx_nombre_indicador_aprendizaje.caracteristicas.label
                                            v-model="fields_evaluacion.group_indicador_aprendizaje.tx_nombre_indicador_aprendizaje.tx_nombre_indicador_aprendizaje"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_evaluacion.group_indicador_aprendizaje.tx_nombre_indicador_aprendizaje.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
-->                                      
                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje"
                                          :rules=fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.caracteristicas.label
                                            v-model="fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro que desea borrar el registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </v-card>          

            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import {  LIST_COMPETENCIAS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'IndicadoresAprendizajeRegistrar',
  data () {
    return {
        total_a_evaluar: 100,
        total_evaluado: 0,
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_competencias: [],
        list_indicador_aprendizaje: [],
        fields_evaluacion_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          co_indicador: '',
          json_perfil: '',
        },  
        defaultItem: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        institucion_perfil: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Criterio',
              align: 'start',
              sortable: false,
              value: 'json_criterio',
            },
            { text: 'Competencia', value: 'tx_competencia' },        
            { text: 'Indicador', value: 'json_indicador' },        
            { text: 'Ponderación', value: 'nu_ponderacion_indicador_aprendizaje' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_evaluacion','currentUser']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    sumPesos(items) {
      return items.reduce((a, b) => {
        return a + parseFloat(b['valor_real'].toFixed(2));
    }, 0);
    },    
    por_evaluar(params) {
      //var evaluado = 0;
        console.log('Criterio: ', params.criterio )        
        console.log('Indicador: ', params.co_indicador )        
        console.log('peso indicador: ', params.peso_indicador )        
        console.log('peso criterio: ', params.peso_criterio )        
        console.log('Competencia: ', params.nu_id_competencia )        
        console.log('params: ', params )        

            var searchCriterio = this.fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador.find(element => element.criterio === params.criterio);
            console.log('searchCriterio: ', searchCriterio);
            
            var nuevo_valor = []
            var valor_real = 0
            if (searchCriterio === undefined) {
                if ( parseInt(params.peso_indicador) <= parseInt(params.peso_criterio)) {
                    console.log('Valor es valido: ', params.peso_indicador);
                    valor_real = (parseInt(params.peso_indicador) * 100) / params.peso_criterio
                    nuevo_valor.push({
                      co_indicador: params.co_indicador, 
                      tx_descripcion_indicador: params.tx_descripcion_indicador, 
                      peso_indicador: params.peso_indicador,
                      valor_real: valor_real
                    })
                    this.fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador.push({
                            criterio: params.criterio,  
                            peso_criterio: params.peso_criterio, 
                            nu_id_competencia: params.nu_id_competencia, 
                            tx_competencia: params.tx_competencia,                             
                            indicador: nuevo_valor
                          });            
                    return  params.peso_indicador

                } else {
                  console.log('Valor mayor al criterio: ', params.peso_criterio);
                  valor_real = (parseInt(params.peso_criterio) * 100) / params.peso_criterio
                  nuevo_valor.push({
                      co_indicador: params.co_indicador, 
                      tx_descripcion_indicador: params.tx_descripcion_indicador, 
                      peso_indicador: params.peso_criterio,
                      valor_real: valor_real
                  })                  
                  this.fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador.push({
                    criterio: params.criterio, 
                    peso_criterio: params.peso_criterio, 
                    nu_id_competencia: params.nu_id_competencia, 
                    tx_competencia: params.tx_competencia,                             
                    indicador: nuevo_valor
                  });            
                  return  params.peso_criterio
                }

            } else {
                console.log(this.fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador, ' ya existe en la colección de verduras.');
                console.log(searchCriterio, ' ya existe en la colección de verduras.');
                console.log(searchCriterio['peso_criterio'], ' Criterio y Indicador.');
                console.log(searchCriterio['indicador'], ' indicador.');
                var total_guardado = 0
                for (var prop in searchCriterio['indicador']) {
                  total_guardado = total_guardado + parseInt(searchCriterio['indicador'][prop].peso_indicador)
                }
                console.log('Peso total indicador: ', total_guardado,  parseInt(params.peso_indicador))        
                total_guardado = total_guardado + parseInt(params.peso_indicador)
                if (total_guardado <= parseInt(params.peso_criterio)) {
                  console.log('Valor es valido: ', total_guardado);
                  valor_real = (parseInt(params.peso_indicador) * 100) / params.peso_criterio
                  searchCriterio.indicador.push({
                    co_indicador: params.co_indicador, 
                    tx_descripcion_indicador: params.tx_descripcion_indicador, 
                    indicador: params.indicador, 
                    valor_real: valor_real, 
                    peso_indicador: params.peso_indicador
                    });            
                  return  params.peso_indicador
                } else {
                    total_guardado = total_guardado - parseInt(params.peso_indicador)
                    total_guardado = parseInt(params.peso_criterio) - total_guardado
                    if (total_guardado > 0) {
                      valor_real = (parseInt(total_guardado) * 100) / params.peso_criterio
                      searchCriterio.indicador.push({
                        co_indicador: params.co_indicador, 
                        tx_descripcion_indicador: params.tx_descripcion_indicador, 
                        indicador: params.indicador, 
                        valor_real: valor_real, 
                        peso_indicador: total_guardado
                      });                                    
                    }
                    console.log('Valor mayor al criterio: ', total_guardado);
                    return  total_guardado
                }
            }

    },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.institucion_perfil.json_perfil = []
          this.institucion_perfil.json_institucion = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.co_indicador = Object.assign({}, this.fields_evaluacion.group_indicador_aprendizaje.co_indicador.co_indicador)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_evaluacion.group_indicador_aprendizaje.co_indicador.co_indicador[this.editedIndex], this.institucion_perfil)
        } else {
          

          var v_por_evaluar = this.por_evaluar({
              peso_criterio: this.fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.json_criterio_indicador_aprendizaje.nu_ponderacion_criterio,
              criterio: this.fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.json_criterio_indicador_aprendizaje.co_criterio_evaluacion,
              co_indicador: this.fields_evaluacion.group_indicador_aprendizaje.json_indicador.json_indicador.co_indicador,
              tx_descripcion_indicador: this.fields_evaluacion.group_indicador_aprendizaje.json_indicador.json_indicador.tx_descripcion_indicador,
              nu_id_competencia: this.fields_evaluacion.group_indicador_aprendizaje.json_competencia.json_competencia.nu_id_competencia,
              tx_competencia: this.fields_evaluacion.group_indicador_aprendizaje.json_competencia.json_competencia.tx_competencia,
              peso_indicador: this.fields_evaluacion.group_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje.nu_ponderacion_indicador_aprendizaje,
              })
          if (v_por_evaluar > 0) {
              this.fields_evaluacion.group_indicador_aprendizaje.json_group_indicador_aprendizaje.json_group_indicador_aprendizaje.push({            
                json_criterio: this.fields_evaluacion.group_indicador_aprendizaje.json_criterio_indicador_aprendizaje.json_criterio_indicador_aprendizaje,
                tx_competencia: this.fields_evaluacion.group_indicador_aprendizaje.json_competencia.json_competencia.tx_competencia,
                json_indicador: this.fields_evaluacion.group_indicador_aprendizaje.json_indicador.json_indicador,
                nu_ponderacion_indicador_aprendizaje: v_por_evaluar,
              })
            
          }


        }
        this.close()
      },    
      initialize () {
        this.institucion_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_evaluacion.group_indicador_aprendizaje.co_indicador.co_indicador.indexOf(item)
        this.institucion_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_evaluacion.group_indicador_aprendizaje.json_group_indicador_aprendizaje.json_group_indicador_aprendizaje.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_evaluacion.group_indicador_aprendizaje.json_group_indicador_aprendizaje.json_group_indicador_aprendizaje.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchCompetencias(q) {
        this.$store.dispatch(LIST_COMPETENCIAS, q).then(response => {
            this.list_competencias = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
   set_indicador_aprendozaje(q) {
                                              
     console.log('Competencia e indicadores: ', q)
      this.list_indicador_aprendizaje = JSON.parse(q.json_competencia_indicadores)
    },      
    remove (item) {
      var index = this.institucion_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.institucion_perfil.json_perfil.splice(index, 1)
    },    

  },
  
  mounted() {
    console.log('json_group_criterio_evaluacion: ', this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion)
        var params = {
        nu_id_ano_escolar: this.send_datos.plan_evaluacion.nu_id_ano_escolar, 
        nu_id_institucion: this.currentUser.login_institucion.nu_id_institucion,
        nu_id_nivel_educativo: this.send_datos.plan_evaluacion.json_nivel.nu_id_nivel_educativo, 
        nu_id_grado: this.send_datos.plan_evaluacion.nu_id_grado, 
        nu_id_area_formacion: this.send_datos.plan_evaluacion.nu_id_area_formacion,               

        }
        this.searchCompetencias(params)
    
    if (this.send_datos.operacion === 'EDITAR') {
      //console.log('co_indicador: ', JSON.parse(this.fields_evaluacion.group_indicador_aprendizaje.co_indicador.co_indicador))
        //this.fields_evaluacion.group_indicador_aprendizaje.co_indicador.co_indicador = JSON.parse(this.send_datos.usuario.co_indicador)
        //this.fields_evaluacion.group_indicador_aprendizaje.json_perfil.json_perfil = JSON.parse(this.send_datos.usuario.json_perfil)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_evaluacion[prop] === 'object') {
          //           if (this.fields_evaluacion[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_evaluacion[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_evaluacion[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_evaluacion[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.fields_evaluacion.group_indicador_aprendizaje.json_perfil.json_perfil = []
        this.fields_evaluacion.isEditing = true
    }           
  },
    
}
</script>