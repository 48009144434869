<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_evaluacion.group_indicador_logro.json_group_indicador_logro.json_group_indicador_logro"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <template v-slot:item.json_criterio="{ item }">

                      {{ item.json_criterio.criterio }}
                  </template>
                  <template v-slot:item.json_competencia="{ item }">
                      {{ item.json_competencia.tx_competencia }}
                  </template>
                  <template v-slot:item.json_indicador="{ item }">
                      {{ item.json_indicador.tx_descripcion_indicador }}
                  </template>
                  <template v-slot:item.json_indicador_logro="{ item }">
                      {{ item.json_indicador_logro.tx_nombre_indicador_logro }}
                  </template>
                  <!--                   
                  <template v-slot:item.co_indicador="{ item }">
                      {{ item.co_indicador.tx_razon_social }}
                  </template>      -->
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Asociar indicador de logro
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.json_criterio_indicador_logro"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.json_criterio_indicador_logro"
                                                  :items="fields_evaluacion.group_indicador_aprendizaje.json_total_criterio_indicador.json_total_criterio_indicador"
                                                  item-text="criterio"
                                                  item-value="criterio"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  @change="set_competencias"
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      

                                        </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_logro.json_competencia.json_competencia"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_logro.json_competencia.json_competencia"
                                                  :items="list_competencias"
                                                  item-text="tx_competencia"
                                                  item-value="nu_id_competencia"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_logro.json_competencia.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_logro.json_competencia.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      

                                        </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_logro.json_indicador.json_indicador"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_logro.json_indicador.json_indicador"
                                                  :items="fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.json_criterio_indicador_logro.indicador"
                                                  item-text="tx_descripcion_indicador"
                                                  item-value="co_indicador"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_logro.json_indicador.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_logro.json_indicador.caracteristicas.label
                                                  outlined
                                                  return-object
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      
                                        </validation-provider>                    
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_indicador_logro.json_indicador_logro.json_indicador_logro"
                                          rules='required'
                                        >
                                                <v-select
                                                  v-model="fields_evaluacion.group_indicador_logro.json_indicador_logro.json_indicador_logro"
                                                  :items="list_indicadores_logro"
                                                  item-text="tx_nombre_indicador_logro"
                                                  item-value="nu_id_indicador_logro"
                                                  class="input-group--focused text-lg"
                                                  :placeholder=fields_evaluacion.group_indicador_logro.json_indicador_logro.caracteristicas.placeholder
                                                  :error-messages="errors"
                                                  :label=fields_evaluacion.group_indicador_logro.json_indicador_logro.caracteristicas.label
                                                  outlined
                                                  return-object                                                  
                                                  :disabled=!fields_evaluacion.isEditing
                                                ></v-select>                      
                                        </validation-provider>                    
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro que desea borrar el registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import {  LIST_COMPETENCIAS, LIST_INDICADORES_LOGRO } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'IndicadoresLogro',
  data () {
    return {
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_competencias: [],
        list_indicadores_logro: [],
        fields_evaluacion_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          co_indicador: '',
          json_perfil: '',
        },  
        defaultItem: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        institucion_perfil: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Criterio',
              align: 'start',
              sortable: false,
              value: 'json_criterio',
            },
            { text: 'Competencia', value: 'json_competencia' },        
            { text: 'Indicador de aprendizaje', value: 'json_indicador' },        
            { text: 'Indicador de logro', value: 'json_indicador_logro' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_evaluacion','currentUser']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    set_competencias (items) {
      this.list_competencias =[]
      this.list_competencias.push(items)
    },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.institucion_perfil.json_perfil = []
          this.institucion_perfil.json_institucion = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.co_indicador = Object.assign({}, this.fields_evaluacion.group_indicador_logro.co_indicador.co_indicador)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_evaluacion.group_indicador_logro.co_indicador.co_indicador[this.editedIndex], this.institucion_perfil)
        } else {
          console.log('this.editedItem: ', this.fields_evaluacion.group_indicador_logro.json_group_indicador_logro.json_group_indicador_logro)
          this.fields_evaluacion.group_indicador_logro.json_group_indicador_logro.json_group_indicador_logro.push({            
            json_criterio: this.fields_evaluacion.group_indicador_logro.json_criterio_indicador_logro.json_criterio_indicador_logro,
            json_competencia: this.fields_evaluacion.group_indicador_logro.json_competencia.json_competencia,
            json_indicador: this.fields_evaluacion.group_indicador_logro.json_indicador.json_indicador,
            json_indicador_logro: this.fields_evaluacion.group_indicador_logro.json_indicador_logro.json_indicador_logro,
          })

          /*var aa = {
            co_indicador: this.institucion_perfil.co_indicador,
            json_perfil: this.institucion_perfil.json_perfil
          }
          */
        }
        this.close()
      },    
      initialize () {
        this.institucion_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_evaluacion.group_indicador_logro.co_indicador.co_indicador.indexOf(item)
        this.institucion_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_evaluacion.group_indicador_logro.json_group_indicador_logro.json_group_indicador_logro.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_evaluacion.group_indicador_logro.json_group_indicador_logro.json_group_indicador_logro.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchCompetencias(q) {
        this.$store.dispatch(LIST_COMPETENCIAS, q).then(response => {
            this.list_competencias = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
    searchIndicadorLogros(params) {
        this.$store.dispatch(LIST_INDICADORES_LOGRO, params).then(response => {
            this.list_indicadores_logro = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
    remove (item) {
      var index = this.institucion_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.institucion_perfil.json_perfil.splice(index, 1)
    },    

  },
  
  mounted() {
        var params = {
        nu_id_ano_escolar: this.send_datos.plan_evaluacion.nu_id_ano_escolar, 
        nu_id_institucion: this.currentUser.login_institucion.nu_id_institucion,
        nu_id_nivel_educativo: this.send_datos.plan_evaluacion.json_nivel.nu_id_nivel_educativo, 
        nu_id_grado: this.send_datos.plan_evaluacion.nu_id_grado, 
        nu_id_area_formacion: this.send_datos.plan_evaluacion.nu_id_area_formacion,               

        }
    this.searchIndicadorLogros(params)
    

    console.log('paso indicadores de logro')
        console.log('paso indicadores de logro luego')

    if (this.send_datos.operacion === 'EDITAR') {
      console.log('co_indicador: ', )
     } else {
        this.fields_evaluacion.isEditing = true
    }           
  },
    
}
</script>