<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_competencias"
                    :items-per-page="5"
                    :search="search"        
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="indicadores_logro(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.json_competencia_indicadores`]="{item}">
                          <div class="d-flex flex-column">
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_competencia_indicadores)" :key="index">   
                                   
                                <span class="text-xs"> {{ item_01.co_indicador }} </span>
                                <span class="text-xs"> [{{ item_01.tx_nombre_indicador }} ] </span>
                              </div>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        
                  </v-data-table>
              </div>
    </div>

        <v-dialog
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition" 
            v-if="dialogLiteral" :value="true" @input="dialogLiteral = false"                   
            >
            <v-card>
              <v-container>

                <!--<div  class="md:p-2 flex justify-between text-skin-base bg-gray-50">
                    <div class="p-2 text-sm md:text-lg">                        
                        <span>Historia clínica: </span><span><strong>{{ fields_list_competencias.group_list_competencias.tx_list_competencias.tx_list_competencias }}</strong>  </span>                                                
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Paciente: <span><strong>{{ fields_paciente.tx_apellidos.tx_apellidos}} {{ fields_paciente.tx_nombres.tx_nombres}}</strong>  </span>                        
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Fecha de apertura: <span><strong>{{ fields_list_competencias.group_list_competencias.fe_apertura.fe_apertura }}</strong>  </span>
                    </div>        
                    <div class="p-2 text-sm md:text-lg">                        
                        Apeturada por: <span><strong>{{ currentUser.username }}</strong>  </span>
                    </div>        

                </div>                
-->
                <div class="w-full mx-auto my-auto">

                <CompetenciaOpciones :send_datos="send_datos" titulo="titulo"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'
import {CREATE_COMPETENCIA } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import CompetenciaOpciones from "@/views/configuracion/Competencias/CompetenciaOpciones.vue";

export default {
    name: 'IndicadorLogroListar',
    data() {
        return {
            dialogLiteral: false, 
            search: '',
            titulo: 'Competencias',
            list_competencias: [],
            headers: [
              {
                text: 'Nivel educativo',
                align: 'start',
                sortable: false,
                value: 'tx_nivel',
              },          
              {
                text: 'Nivel/Grado/Año',
                sortable: false,
                value: 'tx_nombre_grado',
              },          
              {
                text: 'Área de formación/Aprendizaje',
                sortable: false,
                value: 'tx_descripcion',
              },          
              {
                text: 'Competencia',
                value: 'tx_competencia',
              },          
              { 
                text: 'Indicadores', 
                value: 'json_competencia_indicadores' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
            ],            
        }
    },
    props: {
        send_datos: Object

    },
    computed: {
        ...mapGetters(['fields_institucion']),
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  

        return {
        icons,
        }
    },
    methods: {
    indicadores_logro (id) {
      console.log('Editar id: ', id) // someValue
      this.dialogLiteral = true
    },
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.list_competencias = id
      this.send_datos.operacion = 'EDITAR'
      this.dialogLiteral = true
    },
    return_competencia(msg) {    
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);

            const formData = new FormData()
            formData.append('competencia', JSON.stringify(guardar))

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')

            this.$store.dispatch(CREATE_COMPETENCIA, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro de la institución " + JSON.parse(datos.datos_institucion).tx_razon_social + " fue satisfactorio"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_institucion));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("actualizar_datos_institucion", JSON.stringify(guardar));              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  

          /// hasta aca optimizar


    },          
    }, 
    components: {
      CompetenciaOpciones
    },
    mounted() {
      
    },
    watch: {
    },    


}
</script>