<template>
    <div>
     
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_plan_evaluacion"
                    :items-per-page="5"
                    :search="search"      
                    :expanded.sync="expanded" 
                    :single-expand="singleExpand" 
                    show-expand                                     
                    class="elevation-1"
                  >
                      <template v-slot:item.actions="{ item }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="indicadores_logro(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <header class="grid grid-cols-3 gap-4 bg-gray-300">
                            <!-- Header content -->
                              <div class="...">Criterio</div>
                              <div class="...">Ponderación</div>
                          </header>                          
                            <div class="grid grid-cols-3 gap-4" v-for="(item_01, index) in item.criterio_evaluacions" :key="index">
                              <div class="..."> {{ item_01.tx_denominacion_criterio_evaluacion }}</div>
                              <div class="..."> {{ item_01.nu_ponderacion_criterio }}</div>
                            </div>                        
                        </td>
                      </template>
                        <!-- name -->
                      <!--  <template #[`item.json_competencia_indicadores`]="{item}">
                          <div class="d-flex flex-column">
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_competencia_indicadores)" :key="index">   
                                   
                                <span class="text-xs"> {{ item_01.co_indicador }} </span>
                                <span class="text-xs"> [{{ item_01.tx_nombre_indicador }} ] </span>
                              </div>
                          <small>{{ item.tx_nombres }}</small>
                          </div>
                        </template>          
                        -->
                  </v-data-table>
              </div>
    </div>


    </div>
</template>

<script>
import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
import { LIST_COMPETENCIAS } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
//import {   SET_ERROR } from '@/store/mutations.type'


export default {
    name: 'PlanEvaluacionListar',
    data() {
        return {
          expanded: [],          
          singleExpand: false,
          list_plan_evaluacion: [],
            dialogLiteral: false, 
            search: '',
            titulo: 'Plan Evaluación',
            headers: [
              {
                text: 'Plan de evaluación',
                align: 'start',
                sortable: false,
                value: 'tx_denominacion_plan_evaluacion',
              },          
              {
                text: 'Institucion',
                sortable: false,
                value: 'tx_razon_social',
              },          
              {
                text: 'Nivel educativo',
                sortable: false,
                value: 'tx_nivel',
              },          
              {
                text: 'Nivel/Grado/Año',
                sortable: false,
                value: 'tx_nombre_grado',
              },          
              {
                text: 'Área de formación/Aprendizaje',
                sortable: false,
                value: 'tx_descripcion',
              },          
              {
                text: 'Competencia',
                value: 'tx_competencia',
              },          
              { 
                text: 'Indicadores', 
                value: 'json_competencia_indicadores' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },          
              { text: '', value: 'data-table-expand' },              
            ],            
        }
    },
    props: {
        send_datos: Object

    },
    computed: {
        ...mapGetters(['fields_evaluacion','currentUser']),
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  

        return {
        icons,
        }
    },
    methods: {
      searchCompetencias(params) {
          this.$store.dispatch(LIST_COMPETENCIAS, params).then(response => {
              this.list_competencias = response.rest.items
          }, error => {
              this.$store.commit(SET_ERROR, error.statusText)
          })      
        },
      
    indicadores_logro (id) {
      console.log('Editar id: ', id) // someValue
      this.dialogLiteral = true
    },
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.list_plan_evaluacion = id
      this.send_datos.operacion = 'EDITAR'
      this.dialogLiteral = true
    },
    }, 
    components: {
    },
    mounted() {
    this.searchCompetencias(
      {
        nu_id_ano_escolar: this.send_datos.plan_evaluacion.nu_id_ano_escolar, 
        nu_id_institucion: this.currentUser.login_institucion.nu_id_institucion,
        nu_id_nivel_educativo: this.send_datos.plan_evaluacion.json_nivel.nu_id_nivel_educativo, 
        nu_id_grado: this.send_datos.plan_evaluacion.nu_id_grado, 
        nu_id_area_formacion: this.send_datos.plan_evaluacion.nu_id_area_formacion,               
      }
    )
      

      //this.searchIndicadorLogroes({})
      //this.list_plan_evaluacion_01 = this.list_plan_evaluacion; 
      //console.log('Especialidads: ', this.list_plan_evaluacion)
    },
    watch: {
    },    


}
</script>