<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">

              <validation-provider
                v-slot="{ errors }"
                name="fields_competencias.group_datos_basicos.tx_competencia.tx_competencia"
                :rules=fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.required
              >
                <v-textarea
                  v-model="fields_competencias.group_datos_basicos.tx_competencia.tx_competencia"
                  outlined
                  class="input-group--focused text-lg pt-2"
                  :error-messages="errors"
                  :label=fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.label
                  v-bind="attrs"
                  v-on="on"
                  :disabled=!fields_competencias.isEditing                            
                ></v-textarea>
              </validation-provider>     
                </template>
                <span>{{ fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.tooltip  }} </span>
              </v-tooltip>                
          </div>



          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_INSTITUCION_NIVELES, LIST_PLAN_AREAS, LIST_PLAN_GRADOS  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'CompetenciaDatosBasicos',
  data () {
    return {
        list_instituciones: [],
        list_institucion_niveles: [],
        list_institucion_perfiles: [],
        list_institucion_grados: [],
        list_institucion_areas: [],
        modal: false,                 
        search:null,
        isLoading: false,
        tipo_persona: [],
        list_tipo_institucion: [
          {
            co_tipo: 'pub',
            tx_descripcion: 'Pública'
          },
          {
            co_tipo: 'priv',
            tx_descripcion: 'Privada'
          },
        ],

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },     
  computed: {
    ...mapGetters(['fields_competencias']),
      listaTipoPersona () {
          return this.tipo_persona.map(entry => {
              const Description = entry.tx_tipo_persona.length > this.descriptionLimit
              ? entry.tx_tipo_persona.slice(0, this.descriptionLimit) + '...'
              : entry.tx_tipo_persona

              return Object.assign({}, entry, { Description })
          })
      },             

    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    },  
  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
      searchInstitucionNiveles(q) {
        console.log('searchInstitucion 1: ', q)
        this.$store.dispatch(LIST_INSTITUCION_NIVELES, {nu_id_institucion: q}).then(response => {
            console.log('LIST_INSTITUCION_NIVELES 2: ', response.rest.items)
            this.list_institucion_niveles = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
      setSelectedNivel(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedNivel   : ', this.filtros_temp.json_institucion.nu_id_institucion)
      this.searchInstitucionNiveles(this.filtros_temp.json_institucion.nu_id_institucion, value)
      //this.list_plan_areas = value.json_area       
    },    
    setSelectedGrados(value) {
      //  trigger a mutation, or dispatch an action
      
              console.log('Valores setSelectedGrados   : ', value)
      this.searchGrados(value)

              
    },    
    setSelectedArea(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedArea   : ', value)
      
      this.searchInstitucionArea(value)

    },    
    setSelectedInstitucion(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedInstitucion  : ',value)
      this.list_institucion_perfiles = value.json_perfil       
    },    
    searchGrados(q) {
      var params = {
        nu_id_ano_escolar: q.nu_id_ano_escolar,
        nu_id_institucion: q.nu_id_institucion,
        nu_id_nivel_educativo: q.nu_id_nivel_educativo,
      }
      this.$store.dispatch(LIST_PLAN_GRADOS, params ).then(response => {
          console.log('LIST_PLAN_AREAS 2: ', response.rest.items)
          this.list_institucion_grados = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },      
    searchInstitucionArea(q) {
      var params = {
        json_grados: q,
      }
      this.$store.dispatch(LIST_PLAN_AREAS, params ).then(response => {
          console.log('LIST_PLAN_AREAS 2: ', response.rest.items)
          this.list_institucion_areas = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },      
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.$emit("guardar_institucion_datos_basicos", JSON.stringify(this.fields_competencias.group_datos_basicos));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
    this.list_instituciones.push(this.send_datos.param_competencia.json_institucion)
    //console.log('this.send_datos.param_competencia: ', this.send_datos.param_competencia.json_grados)
    this.list_institucion_niveles.push(this.send_datos.param_competencia.json_nivel)
    //this.list_institucion_grados.push(this.send_datos.param_competencia.json_grados)
    this.list_institucion_areas.push(
      {
        tx_descripcion: this.send_datos.param_competencia.tx_descripcion,
        nu_id_area_formacion: this.send_datos.param_competencia.nu_id_area_formacion,
      })
        this.fields_competencias.group_datos_basicos.nu_id_institucion.nu_id_institucion = this.send_datos.param_competencia.json_institucion  
        this.fields_competencias.group_datos_basicos.nu_id_nivel_educativo.nu_id_nivel_educativo = this.send_datos.param_competencia.json_nivel 
        this.fields_competencias.group_datos_basicos.nu_id_ano_escolar.nu_id_ano_escolar = this.send_datos.param_competencia.nu_id_ano_escolar 
        this.list_institucion_grados.push({
          nu_id_grado: this.send_datos.param_competencia.nu_id_grado,
          tx_nombre_grado: this.send_datos.param_competencia.tx_nombre_grado,
          
        })
        this.fields_competencias.group_datos_basicos.nu_id_grado.nu_id_grado  =  this.send_datos.param_competencia.nu_id_grado

        this.fields_competencias.group_datos_basicos.nu_id_area_formacion.nu_id_area_formacion = {
              tx_descripcion: this.send_datos.param_competencia.tx_descripcion,
              nu_id_area_formacion: this.send_datos.param_competencia.nu_id_area_formacion,
        }
    //this.searchInstitucionNiveles(this.send_datos.institucion.nu_id_institucion)
      //this.searchTipoPersona({})
      //this.fields_competencias.isEditing  = true
  },
    
}
</script>