<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <v-card>
              <v-card-title>
                <div class="flex justify-start text-sm">
                    <div class="w-1/2">
                      <span>{{ total_evaluado }}% Criterios Cargado</span>                      
                      <v-progress-linear color="success" height="8" :value="total_evaluado"></v-progress-linear>
                    </div>
                </div>
                <v-spacer></v-spacer>
              </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >                
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear criterio de evaluación
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                    <span>{{ total_evaluado }}% Criterios</span>
                      
                      <v-progress-linear :value="total_evaluado"></v-progress-linear>

                                      </v-col>
                                      
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion"
                                            :rules=fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.required
                                          >
                                          <v-text-field
                                            :label=fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.label
                                            v-model="fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                          </validation-provider>     
                                            </template>
                                            <span>{{ fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </v-col>

                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion"
                                          :rules=fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.label
                                            v-model="fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio"
                                          :rules=fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.label
                                            v-model="fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_evaluacion.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>

            </v-card>          
        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import {  LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'CriteriosEvaluacionRegistrar',
  data () {
    return {
        total_a_evaluar: 100,
        total_evaluado: 0,
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_perfiles: [],
        fields_evaluacion_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          co_indicador: '',
          json_perfil: '',
        },  
        defaultItem: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        institucion_perfil: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Código criterio',
              align: 'start',
              sortable: false,
              value: 'co_criterio_evaluacion',
            },
            { text: 'Nombre criterio', value: 'tx_denominacion_criterio_evaluacion' },        
            { text: 'Ponderación', value: 'nu_ponderacion_criterio' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_evaluacion']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
    por_evaluar(valor) {
      var evaluado = 0;
      for (var prop in this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion) {
        evaluado = evaluado + parseInt(this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion[prop].nu_ponderacion_criterio)  
      } 
      console.log('Evaluado: ', evaluado)
      console.log('v_por_evaluar: ', parseInt( valor ) + evaluado)
      return (parseInt( valor ) + evaluado )
    },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.institucion_perfil.json_perfil = []
          this.institucion_perfil.json_institucion = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.co_indicador = Object.assign({}, this.fields_evaluacion.group_criterio_evaluacion.co_indicador.co_indicador)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_evaluacion.group_criterio_evaluacion.co_indicador.co_indicador[this.editedIndex], this.institucion_perfil)
        } else {
          console.log('this.editedItem: ', this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion)
          var v_por_evaluar = this.por_evaluar(this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio)
          if (v_por_evaluar <= this.total_a_evaluar) {
            this.total_evaluado = v_por_evaluar
            //this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio = this.total_a_evaluar -  this.total_evaluado
          } else {
            this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio =  this.total_a_evaluar - (v_por_evaluar - this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio)
            this.total_evaluado =  this.total_evaluado + this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio
          }
          if (this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio > 0) {
              this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion.push({            
                co_criterio_evaluacion: this.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion,
                tx_denominacion_criterio_evaluacion: this.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion,
                nu_ponderacion_criterio: this.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio,
              })
            
          }

        }
        this.close()
      },    
      initialize () {
        this.institucion_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_evaluacion.group_criterio_evaluacion.co_indicador.co_indicador.indexOf(item)
        this.institucion_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchPerfiles(q) {
      this.$store.dispatch(LIST_PERFIL, {q: q}).then(response => {
          console.log('searchLIST_PERFIL: ', response )
          this.list_perfiles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
    remove (item) {
      var index = this.institucion_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.institucion_perfil.json_perfil.splice(index, 1)
    },    

  },
  
  mounted() {
    if (this.send_datos.operacion === 'EDITAR') {
      //console.log('co_indicador: ', JSON.parse(this.fields_evaluacion.group_criterio_evaluacion.co_indicador.co_indicador))
        //this.fields_evaluacion.group_criterio_evaluacion.co_indicador.co_indicador = JSON.parse(this.send_datos.usuario.co_indicador)
        //this.fields_evaluacion.group_criterio_evaluacion.json_perfil.json_perfil = JSON.parse(this.send_datos.usuario.json_perfil)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_evaluacion[prop] === 'object') {
          //           if (this.fields_evaluacion[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_evaluacion[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_evaluacion[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_evaluacion[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.fields_evaluacion.group_criterio_evaluacion.json_perfil.json_perfil = []
        this.fields_evaluacion.isEditing = true
    }           
  },
    
}
</script>