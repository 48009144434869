<template>
  <div class="p-2">
        <ValidationObserver ref="datos_basicos">
            <div>
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_evaluacion.group_datos_basicos.nu_id_lapso.nu_id_lapso"
                  rules='required'
                >
                        <v-select
                          v-model="fields_evaluacion.group_datos_basicos.nu_id_lapso.nu_id_lapso"
                          :items="list_lapsos"
                          item-text="tx_nombre_lapso"
                          item-value="nu_id_lapso"
                          class="input-group--focused text-lg"
                          :placeholder=fields_evaluacion.group_datos_basicos.nu_id_lapso.caracteristicas.placeholder
                          :error-messages="errors"
                          :label=fields_evaluacion.group_datos_basicos.nu_id_lapso.caracteristicas.label
                          outlined
                          :disabled=!fields_evaluacion.isEditing
                        ></v-select>                      

                </validation-provider>                    
          </div> 
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_evaluacion.group_datos_basicos.co_plan_evaluacion.co_plan_evaluacion"
                  rules='required'
                >
                    <v-text-field
                      v-model="fields_evaluacion.group_datos_basicos.co_plan_evaluacion.co_plan_evaluacion"
                      outlined
                      class="input-group--focused text-lg pt-2"
                      :error-messages="errors"
                      :label=fields_evaluacion.group_datos_basicos.co_plan_evaluacion.caracteristicas.label
                      v-bind="attrs"
                      v-on="on"
                      :disabled=!fields_evaluacion.isEditing                            
                    ></v-text-field>
                </validation-provider>     
              </template>
              <span>{{ fields_evaluacion.group_datos_basicos.co_plan_evaluacion.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.tx_denominacion_plan_evaluacion"
                  rules='required'
                >
                    <v-text-field
                      v-model="fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.tx_denominacion_plan_evaluacion"
                      outlined
                      class="input-group--focused text-lg pt-2"
                      :error-messages="errors"
                      :label=fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.caracteristicas.label
                      v-bind="attrs"
                      v-on="on"
                      :disabled=!fields_evaluacion.isEditing                            
                    ></v-text-field>
                </validation-provider>     
              </template>
              <span>{{ fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>
          <div>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  name="fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.tx_descripcion_plan_evaluacion"
                  rules='required'
                >
                    <v-text-field
                      v-model="fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.tx_descripcion_plan_evaluacion"
                      outlined
                      class="input-group--focused text-lg pt-2"
                      :error-messages="errors"
                      :label=fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.caracteristicas.label
                      v-bind="attrs"
                      v-on="on"
                      :disabled=!fields_evaluacion.isEditing                            
                    ></v-text-field>
                </validation-provider>     
              </template>
              <span>{{ fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.caracteristicas.tooltip  }} </span>
            </v-tooltip>                
          </div>

          </ValidationObserver>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {  LIST_INSTITUCION_NIVELES, LIST_PLAN_AREAS, LIST_PLAN_GRADOS, LIST_LAPSO  } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'PlanEvaluacionDatosBasicos',
  data () {
    return {
        list_lapsos: [],
        list_instituciones: [],
        list_institucion_niveles: [],
        list_institucion_perfiles: [],
        list_institucion_grados: [],
        list_institucion_areas: [],
        modal: false,                 
        search:null,
        isLoading: false,
        tipo_persona: [],
        list_tipo_institucion: [
          {
            co_tipo: 'pub',
            tx_descripcion: 'Pública'
          },
          {
            co_tipo: 'priv',
            tx_descripcion: 'Privada'
          },
        ],

    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
  },     
  computed: {
    ...mapGetters(['fields_evaluacion']),
      listaTipoPersona () {
          return this.tipo_persona.map(entry => {
              const Description = entry.tx_tipo_persona.length > this.descriptionLimit
              ? entry.tx_tipo_persona.slice(0, this.descriptionLimit) + '...'
              : entry.tx_tipo_persona

              return Object.assign({}, entry, { Description })
          })
      },             

    fecha_reg_max() {
       return new Date().toISOString().slice(0,10);

    },  
  },
  props: {
    send_datos: Object
  },
  components: {

  },    
  methods: {
      searchLapsos(q) {
        this.$store.dispatch(LIST_LAPSO, {nu_id_ano_escolar: q}).then(response => {
            this.list_lapsos = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })
      },
      searchInstitucionNiveles(q) {
        console.log('searchInstitucion 1: ', q)
        this.$store.dispatch(LIST_INSTITUCION_NIVELES, {nu_id_lapso: q}).then(response => {
            console.log('LIST_INSTITUCION_NIVELES 2: ', response.rest.items)
            this.list_institucion_niveles = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },      
      setSelectedNivel(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedNivel   : ', this.filtros_temp.json_institucion.nu_id_lapso)
      this.searchInstitucionNiveles(this.filtros_temp.json_institucion.nu_id_lapso, value)
      //this.list_plan_areas = value.json_area       
    },    
    setSelectedGrados(value) {
      //  trigger a mutation, or dispatch an action
      
              console.log('Valores setSelectedGrados   : ', value)
      this.searchGrados(value)

              
    },    
    setSelectedArea(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedArea   : ', value)
      
      this.searchInstitucionArea(value)

    },    
    setSelectedInstitucion(value) {
      //  trigger a mutation, or dispatch an action  
      console.log('Valores setSelectedInstitucion  : ',value)
      this.list_institucion_perfiles = value.json_perfil       
    },    
    searchGrados(q) {
      var params = {
        nu_id_ano_escolar: q.nu_id_ano_escolar,
        nu_id_lapso: q.nu_id_lapso,
        nu_id_nivel_educativo: q.nu_id_nivel_educativo,
      }
      this.$store.dispatch(LIST_PLAN_GRADOS, params ).then(response => {
          console.log('LIST_PLAN_AREAS 2: ', response.rest.items)
          this.list_institucion_grados = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },      
    searchInstitucionArea(q) {
      var params = {
        json_grados: q,
      }
      this.$store.dispatch(LIST_PLAN_AREAS, params ).then(response => {
          console.log('LIST_PLAN_AREAS 2: ', response.rest.items)
          this.list_institucion_areas = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },      
    async guardar () {
        const isValid = await this.$refs.datos_basicos.validate()
        if (!isValid) {
            return true
        }
        this.$emit("guardar_institucion_datos_basicos", JSON.stringify(this.fields_evaluacion.group_datos_basicos));
    },              
    async cancelar () {
        this.$notify({
        group: 'error',
        type: 'Info',
        title: 'Mensaje importante',
        classes: 'w-full text-lg',
        text: 'Operación cancelada!'
        });      
    //this.loading4 = true 
        this.$emit("guardar_enfermedad_actual", JSON.stringify(this.fields_historia_clinica));

    this.fields_historia_clinica.isEditing = !this.fields_historia_clinica.isEditing     

    }, 
          




  },
  
  mounted() {
    this.fields_evaluacion.isEditing  = true
    this.searchLapsos()
    this.list_instituciones.push({
      tx_razon_social: this.send_datos.plan_evaluacion.tx_razon_social,
      nu_id_lapso: this.send_datos.plan_evaluacion.nu_id_lapso,

    })
    this.searchInstitucionNiveles(this.send_datos.plan_evaluacion.nu_id_lapso)
      //this.searchTipoPersona({})
  },
    
}
</script>