var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('ValidationObserver',{ref:"datos_basicos"},[_c('div',[_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_datos_basicos.nu_id_lapso.nu_id_lapso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"input-group--focused text-lg",attrs:{"items":_vm.list_lapsos,"item-text":"tx_nombre_lapso","item-value":"nu_id_lapso","placeholder":_vm.fields_evaluacion.group_datos_basicos.nu_id_lapso.caracteristicas.placeholder,"error-messages":errors,"label":_vm.fields_evaluacion.group_datos_basicos.nu_id_lapso.caracteristicas.label,"outlined":"","disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_datos_basicos.nu_id_lapso.nu_id_lapso),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_datos_basicos.nu_id_lapso, "nu_id_lapso", $$v)},expression:"fields_evaluacion.group_datos_basicos.nu_id_lapso.nu_id_lapso"}})]}}])})],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_datos_basicos.co_plan_evaluacion.co_plan_evaluacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_evaluacion.group_datos_basicos.co_plan_evaluacion.caracteristicas.label,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_datos_basicos.co_plan_evaluacion.co_plan_evaluacion),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_datos_basicos.co_plan_evaluacion, "co_plan_evaluacion", $$v)},expression:"fields_evaluacion.group_datos_basicos.co_plan_evaluacion.co_plan_evaluacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_datos_basicos.co_plan_evaluacion.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.tx_denominacion_plan_evaluacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.caracteristicas.label,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.tx_denominacion_plan_evaluacion),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion, "tx_denominacion_plan_evaluacion", $$v)},expression:"fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.tx_denominacion_plan_evaluacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_datos_basicos.tx_denominacion_plan_evaluacion.caracteristicas.tooltip)+" ")])])],1),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.tx_descripcion_plan_evaluacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"outlined":"","error-messages":errors,"label":_vm.fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.caracteristicas.label,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.tx_descripcion_plan_evaluacion),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion, "tx_descripcion_plan_evaluacion", $$v)},expression:"fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.tx_descripcion_plan_evaluacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_datos_basicos.tx_descripcion_plan_evaluacion.caracteristicas.tooltip)+" ")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }