<template>
  <div class="">
    <div>
      <EvaluacionHeader :titulo="titulo" /> 
    </div>  

    <div class="md:flex md:items-start">
      <div class="w-full">
          <ul class="nav nav-tabs flex flex-col  justify-end md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
            role="tablist">
            <li class="nav-item flex-auto text-center" @click="handleClick('group_ano_escolar')" role="presentation">
              <a href="#tabs-institucionFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-institucion-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-institucionFill" role="tab"
                aria-controls="tabs-institucionFill" aria-selected="false">Institución</a>
            </li>
            <li class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-wizardFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-wizard-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-wizardFill" role="tab"
                aria-controls="tabs-wizardFill" aria-selected="true">Áreas de Formación / Aprendizaje</a>
            </li>

          </ul>
          <div class="tab-content" id="tabs-tabContentFill">
            <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
              <NotificacionInterna :mensaje="mensaje" />  
            </div>
            <div class="tab-pane fade  show active" id="tabs-institucionFill" role="tabpanel" aria-labelledby="tabs-institucion-tabFill">
                  <EvaluacionInstitucionListar titulo=""  :send_datos="send_datos"/>        
            </div>
            <div class="tab-pane fade" id="tabs-wizardFill" role="tabpanel" aria-labelledby="tabs-wizard-tabFill">
              <div class=" border-2 rounded-md shadow-md">
                  <EvaluacionAreasFormacionListar titulo="" :send_datos="send_datos"/>        
              </div>
            </div>
          </div>        
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import EvaluacionInstitucionListar from "@/views/configuracion/Evaluacion/EvaluacionInstitucionListar.vue";
//import Evaluacion from "@/views/configuracion/Evaluacion.vue";
import EvaluacionAreasFormacionListar from "@/views/configuracion/Evaluacion/EvaluacionAreasFormacionListar.vue";


import EvaluacionHeader from "@/components/comunes/HeaderModulo.vue";

const moment = require('moment');

  export default {
    name: 'EvaluacionOpciones',
    data() {
        return {
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_institucion_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupo_ano_escolar: [],
            fields_institucion: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_institucion','fields_persona']),
        hoy() {
            const today = new Date();
            var fecha2 = moment(today);
            return fecha2.format('DD-MM-YYYY');
        },       


    },    
    props: {
        send_datos: Object,
        titulo: String,
    },
    components: {
      NotificacionInterna,
      EvaluacionAreasFormacionListar,
      EvaluacionInstitucionListar,
      EvaluacionHeader,
    },
    methods: {
       async limpiar () {
            for (const property in this.fields_institucion) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_institucion[property]) {
                          this.fields_institucion[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        handleClick(e) {
          this.locales.grupo_ano_escolar = []
          this.locales.grupo_ano_escolar.push(this.fields_institucion.[e]) 
        },     
        cerrar(msg) {
          console.log('actualizar_datos_institucion: Actualizar')
          this.$emit("actualizar_datos_institucion", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },

        // Paso #2
    },
    mounted() {

    },         

    
  }
</script>

<style lang="scss" scoped>

</style>