<template>
  <div class="">
        <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
          <NotificacionInterna :mensaje="mensaje" />  
        </div>
        <div class="">
            <div>
                <v-data-table
                  :headers="headers"
                  :items="fields_competencias.group_datos_basicos.json_competencia_indicadores.json_competencia_indicadores"
                  sort-by="calories"
                  class="elevation-1 mb-10"

                >
                
                  <!-- <template v-slot:item.json_perfil="{ item }">
                      <div v-for="(item_esp, index) in item.json_perfil" :key="index">

                      {{ item_esp.tx_nombre_perfil }}
                      </div>
                  </template>      -->
                  <!--                   
                  <template v-slot:item.co_indicador="{ item }">
                      {{ item.co_indicador.tx_razon_social }}
                  </template>      -->
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                            <v-spacer></v-spacer>
                            <v-dialog
                              v-model="dialog"
                              max-width="500px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Crear indicador
                                </v-btn>

                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                      >
                                          <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">

                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="fields_competencias.group_datos_basicos.tx_competencia.tx_competencia"
                                            :rules=fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.required
                                          >
                                            <v-textarea
                                              v-model="fields_competencias.group_datos_basicos.tx_competencia.tx_competencia"
                                              outlined
                                              class="input-group--focused text-lg pt-2"
                                              :error-messages="errors"
                                              :label=fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.label
                                              v-bind="attrs"
                                              v-on="on"
                                              :disabled=fields_competencias.isEditing                            
                                            ></v-textarea>
                                          </validation-provider>     
                                            </template>
                                            <span>{{ fields_competencias.group_datos_basicos.tx_competencia.caracteristicas.tooltip  }} </span>
                                          </v-tooltip>                
                                      </v-col>

                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_competencias.group_datos_basicos.co_indicador.co_indicador"
                                          :rules=fields_competencias.group_datos_basicos.co_indicador.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_competencias.group_datos_basicos.co_indicador.caracteristicas.label
                                            v-model="fields_competencias.group_datos_basicos.co_indicador.co_indicador"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_competencias.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_competencias.group_datos_basicos.co_indicador.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="fields_competencias.group_datos_basicos.tx_nombre_indicador.tx_nombre_indicador"
                                          :rules=fields_competencias.group_datos_basicos.tx_nombre_indicador.caracteristicas.required
                                        >
                                          <v-text-field
                                            :label=fields_competencias.group_datos_basicos.tx_nombre_indicador.caracteristicas.label
                                            v-model="fields_competencias.group_datos_basicos.tx_nombre_indicador.tx_nombre_indicador"
                                            outlined
                                            class="input-group--focused text-lg pt-2"
                                            :error-messages="errors"
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="!fields_competencias.isEditing"
                                          ></v-text-field>
                                        </validation-provider>                    
                                        </template>
                                        <span>{{fields_competencias.group_datos_basicos.tx_nombre_indicador.caracteristicas.tooltip  }} </span>
                                      </v-tooltip>                
                                      </v-col>
                                      <v-col
                                        cols="12"
                                      >
                                            <v-tooltip right>
                                              <template v-slot:activator="{ on, attrs }">

                                            <validation-provider
                                              v-slot="{ errors }"
                                              name="fields_competencias.group_datos_basicos.tx_descripcion_indicador.tx_descripcion_indicador"
                                              :rules=fields_competencias.group_datos_basicos.tx_descripcion_indicador.caracteristicas.required
                                            >
                                              <v-textarea
                                                v-model="fields_competencias.group_datos_basicos.tx_descripcion_indicador.tx_descripcion_indicador"
                                                outlined
                                                class="input-group--focused text-lg pt-2"
                                                :error-messages="errors"
                                                :label=fields_competencias.group_datos_basicos.tx_descripcion_indicador.caracteristicas.label
                                                v-bind="attrs"
                                                v-on="on"
                                                :disabled=!fields_competencias.isEditing                            
                                              ></v-textarea>
                                            </validation-provider>     
                                              </template>
                                              <span>{{ fields_competencias.group_datos_basicos.tx_descripcion_indicador.caracteristicas.tooltip  }} </span>
                                            </v-tooltip>                
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancelar
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                  >
                                    Guardar
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5">Esta seguro que desea borrar este registro?</v-card-title>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Guardar</v-btn>
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      {{ icons.mdiPencil }}                                            
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      {{ icons.mdiDelete }}                                            
                      
                    </v-icon>

                  </template>
                  <template v-slot:no-data>
                    <span>
                      No hay información
                    </span>
                  </template>
              </v-data-table>
            </div>

        </div>                  
       
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import {  LIST_PERFIL } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'

import "@/common/rules.js";
import { reactive  } from "@vue/composition-api"
import {
  mdiClose,
  mdiAccount,
  mdiAlarmMultiple,
  mdiAlarmPlus,
  mdiPencil,
  mdiDelete,
  mdiCalendar,
  mdiAccountHeart,
  mdiNeedle,
  mdiRadiologyBoxOutline,
  mdiCached
} from '@mdi/js'


export default {
  name: 'IndicadorLogroRegistrar',
  data () {
    return {
        mensaje: {
          satisfactorio: {
            status: false,
            texto: ''
          },
          nosatisfactorio: {
            status: false,
            texto: ''
          },
        },
        list_perfiles: [],
        fields_competencias_temp: [],
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          co_indicador: '',
          json_perfil: '',
        },  
        defaultItem: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   
        institucion_perfil: {
          co_indicador: [],
          json_perfil: [],
          caracteristicas: {
            nombre: 'co_indicador',
            label: 'Institución *',
            tipo: 'JSON',
            tamano: 'text-sm',
            placeholder: 'Seleccione la institución',
            tooltip: 'Institución',
            tooltip_pos:'bottom',
            required: 'required',        
            default: ''
          }
        },   

        headers: [
            {
              text: 'Código indicador',
              align: 'start',
              sortable: false,
              value: 'co_indicador',
            },
            { text: 'Nombre indicador', value: 'tx_nombre_indicador' },        
            { text: 'descripción del indicador', value: 'tx_descripcion_indicador' },        
            { text: 'Actions', value: 'actions', sortable: false },
        ],
    }
  },
  setup() {
    const icons = reactive({
        mdiClose,
        mdiAccount,
        mdiAlarmMultiple,
        mdiAlarmPlus,
        mdiPencil,
        mdiCalendar,
        mdiAccountHeart,
        mdiNeedle,
        mdiRadiologyBoxOutline,
        mdiCached,
        mdiDelete,
      });  


    return {
      icons
    }
  },  
  watch: {
  },     
  computed: {
    ...mapGetters(['fields_competencias']),
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },
  props: {
    send_datos: Object
  },
  components: {
    NotificacionInterna
  },    
  methods: {
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.institucion_perfil.json_perfil = []
          this.institucion_perfil.json_institucion = {}
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.co_indicador = Object.assign({}, this.fields_competencias.group_datos_basicos.co_indicador.co_indicador)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.fields_competencias.group_datos_basicos.co_indicador.co_indicador[this.editedIndex], this.institucion_perfil)
        } else {
          console.log('this.editedItem: ', this.fields_competencias.group_datos_basicos.json_competencia_indicadores.json_competencia_indicadores)
          this.fields_competencias.group_datos_basicos.json_competencia_indicadores.json_competencia_indicadores.push({            
            co_indicador: this.fields_competencias.group_datos_basicos.co_indicador.co_indicador,
            tx_nombre_indicador: this.fields_competencias.group_datos_basicos.tx_nombre_indicador.tx_nombre_indicador,
            tx_descripcion_indicador: this.fields_competencias.group_datos_basicos.tx_descripcion_indicador.tx_descripcion_indicador,
          })

          /*var aa = {
            co_indicador: this.institucion_perfil.co_indicador,
            json_perfil: this.institucion_perfil.json_perfil
          }
          */
        }
        this.close()
      },    
      initialize () {
        this.institucion_perfil = this.defaultItem
      },

      editItem (item) {
        this.editedIndex = this.fields_competencias.group_datos_basicos.co_indicador.co_indicador.indexOf(item)
        this.institucion_perfil = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.fields_competencias.group_datos_basicos.json_competencia_indicadores.json_competencia_indicadores.indexOf(item)
        console.log('editedIndex: ', this.editedIndex)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.fields_competencias.group_datos_basicos.json_competencia_indicadores.json_competencia_indicadores.splice(this.editedIndex, 1)
        this.closeDelete()
      },
    searchPerfiles(q) {
      this.$store.dispatch(LIST_PERFIL, {q: q}).then(response => {
          console.log('searchLIST_PERFIL: ', response )
          this.list_perfiles = response.rest.items
      }, error => {
          this.$store.commit(SET_ERROR, error.statusText)
      })      
    },  
    remove (item) {
      var index = this.institucion_perfil.json_perfil.findIndex(i => i.nu_id_perfil === item.nu_id_perfil);
      if (index >= 0) this.institucion_perfil.json_perfil.splice(index, 1)
    },    

  },
  
  mounted() {
    if (this.send_datos.operacion === 'EDITAR') {
      //console.log('co_indicador: ', JSON.parse(this.fields_competencias.group_datos_basicos.co_indicador.co_indicador))
        //this.fields_competencias.group_datos_basicos.co_indicador.co_indicador = JSON.parse(this.send_datos.usuario.co_indicador)
        //this.fields_competencias.group_datos_basicos.json_perfil.json_perfil = JSON.parse(this.send_datos.usuario.json_perfil)
          // for (var prop in this.send_datos.usuario) {
          //     if(typeof this.fields_competencias[prop] === 'object') {
          //           if (this.fields_competencias[prop]['caracteristicas']['tipo'] === 'JSON') {
          //             this.fields_competencias[prop][prop] = JSON.parse(this.send_datos.usuario[prop])
          //           } else {
          //             this.fields_competencias[prop][prop] = this.send_datos.usuario[prop]
          //           }
          //     } else {
          //           this.fields_competencias[prop][prop] = this.send_datos.usuario[prop]
          //     }
          // }
    } else {
        //this.limpiar()
        //this.fields_competencias.group_datos_basicos.json_perfil.json_perfil = []
        this.fields_competencias.isEditing = true
    }           
  },
    
}
</script>