var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.mensaje.satisfactorio.status || _vm.mensaje.nosatisfactorio.status )?_c('div',{staticClass:"sticky top-0 z-50"},[_c('NotificacionInterna',{attrs:{"mensaje":_vm.mensaje}})],1):_vm._e(),_c('div',{},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"flex justify-start text-sm"},[_c('div',{staticClass:"w-1/2"},[_c('span',[_vm._v(_vm._s(_vm.total_evaluado)+"% Criterios Cargado")]),_c('v-progress-linear',{attrs:{"color":"success","height":"8","value":_vm.total_evaluado}})],1)]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1 mb-10",attrs:{"headers":_vm.headers,"items":_vm.fields_evaluacion.group_criterio_evaluacion.json_group_criterio_evaluacion.json_group_criterio_evaluacion,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Crear criterio de evaluación ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.total_evaluado)+"% Criterios")]),_c('v-progress-linear',{attrs:{"value":_vm.total_evaluado}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion","rules":_vm.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"label":_vm.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.label,"outlined":"","error-messages":errors,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion, "co_criterio_evaluacion", $$v)},expression:"fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.co_criterio_evaluacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_criterio_evaluacion.co_criterio_evaluacion.caracteristicas.tooltip)+" ")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion","rules":_vm.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"label":_vm.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.label,"outlined":"","error-messages":errors,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion, "tx_denominacion_criterio_evaluacion", $$v)},expression:"fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.tx_denominacion_criterio_evaluacion"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_criterio_evaluacion.tx_denominacion_criterio_evaluacion.caracteristicas.tooltip)+" ")])])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio","rules":_vm.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-group--focused text-lg pt-2",attrs:{"label":_vm.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.label,"outlined":"","error-messages":errors,"disabled":!_vm.fields_evaluacion.isEditing},model:{value:(_vm.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio),callback:function ($$v) {_vm.$set(_vm.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio, "nu_ponderacion_criterio", $$v)},expression:"fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.nu_ponderacion_criterio"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.fields_evaluacion.group_criterio_evaluacion.nu_ponderacion_criterio.caracteristicas.tooltip)+" ")])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Guardar")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v(" No hay información ")])]},proxy:true}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }