<template>
  <div class="">
    <div>
      <PlanEvaluacionHeader :titulo="titulo" /> 
    </div>  
    <div class="pl-2 font-bold">
      {{  send_datos.plan_evaluacion.tx_nivel }} / {{  send_datos.plan_evaluacion.tx_nombre_grado }} / {{  send_datos.plan_evaluacion.tx_descripcion }}
    </div>  

    <div class="md:flex md:items-start">
      
      <div class="w-full">

          <ul class="nav nav-tabs flex flex-col  justify-end md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tabFill"
            role="tablist">
            <li class="nav-item flex-auto text-center" @click="handleClick('group_ano_escolar_01')" role="presentation">
              <a href="#tabsIndicadoresFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
                active
              " id="tabs-indicadores-tabFill" data-bs-toggle="pill" data-bs-target="#tabsIndicadoresFill" role="tab"
                aria-controls="tabsIndicadoresFill" aria-selected="false">Planes de Evaluación</a>
            </li>
            <li class="nav-item flex-auto text-center" role="presentation">
              <a href="#tabs-wizardPlanEvaluacionFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-wizardPlanEvaluacion-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-wizardPlanEvaluacionFill" role="tab"
                aria-controls="tabs-wizardPlanEvaluacionFill" aria-selected="true">Wizard</a>
            </li>
            <li class="nav-item flex-auto text-center" @click="cerrar()" role="presentation">
              <a href="#tabs-cerrarFill" class="
                nav-link
                w-full
                block
                font-medium
                text-xs
                leading-tight
                uppercase
                border-x-0 border-t-0 border-b-2 border-transparent
                px-6
                py-3
                my-2
                hover:border-transparent hover:bg-gray-100
                focus:border-transparent
              " id="tabs-cerrar-tabFill" data-bs-toggle="pill" data-bs-target="#tabs-cerrarFill" role="tab"
                aria-controls="tabs-cerrarFill" aria-selected="true">Salir</a>
            </li>

          </ul>
          <div class="tab-content" id="tabs-tabContentFill">
            <div  class="sticky top-0 z-50" v-if="mensaje.satisfactorio.status || mensaje.nosatisfactorio.status ">
              <NotificacionInterna :mensaje="mensaje" />  
            </div>
            <div class="tab-pane fade  show active" id="tabsIndicadoresFill" role="tabpanel" aria-labelledby="tabs-indicadores-tabFill">
                  <PlanEvaluacionListar titulo="" @guardar_plan_evaluacion="return_wizardPlanEvaluacion" :send_datos="send_datos"/>        
            </div>
            <div class="tab-pane fade" id="tabs-wizardPlanEvaluacionFill" role="tabpanel" aria-labelledby="tabs-wizardPlanEvaluacion-tabFill">
              <div class=" border-2 rounded-md shadow-md">
                <Wizard titulo="" @guardar_plan_evaluacion="return_wizardPlanEvaluacion" :send_datos="send_datos"/>
              </div>
            </div>
          </div>        
      </div>
    </div>
</div>

</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_PLAN_EVALUACION_WIZARD, LIST_PLAN_EVALUACION } from '@/store/actions.type'
import { SET_ERROR } from '@/store/mutations.type'
import NotificacionInterna from "@/components/comunes/NotificacionInterna";
import PlanEvaluacionListar from "@/views/configuracion/Evaluacion/plan_evaluacion/PlanEvaluacionListar.vue";
import Wizard from "@/views/configuracion/Evaluacion/plan_evaluacion/Wizard/Wizard";
import PlanEvaluacionHeader from "@/components/comunes/HeaderModulo.vue";
  export default {
    name: 'PlanEvaluacionOpciones',
    data() {
        return {
          tabs: {
            group_usuario:{
              active: '',
              class: ''
            },
          },
          fields_evaluacion_temp: '',
          mensaje: {
            satisfactorio: {
              status: false,
              texto: ''
            },
            nosatisfactorio: {
              status: false,
              texto: ''
            },
          },
          locales:{
            grupo_ano_escolar: [],
            fields_evaluacion: ''
          },
          isHistoria: false,
          pestana: {
            collapseDatosBasico: '',
            collapseHistoriaClinica: '',      
            collapseMotivo: '',
            collapseEnfermedadActual: '',
            collapseAntecedentesEnfermedadActual: '',
            collapseAntecedentesPersonales: '',
            collapseAntecedentesFamiliares: '',
            collapseExamenFisico: ''            
          }
        }
    },    
    computed: {
        ...mapGetters(['fields_evaluacion','currentUser']),
    },    
    props: {
        send_datos: Object,
        titulo: String
    },
    components: {
      NotificacionInterna,
      Wizard,
      PlanEvaluacionListar,
      PlanEvaluacionHeader,
    },
    methods: {
    searchPlanEvaluacion(params) {
        this.$store.dispatch(LIST_PLAN_EVALUACION, params).then(response => {
            this.send_datos.list_plan_evaluacion = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        })      
      },
  
       async limpiar () {
            for (const property in this.fields_evaluacion) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_evaluacion[property]) {
                          this.fields_evaluacion[property][property_01][property_01] = ''
                      }
                  }
            }
            for (const property in this.fields_persona) {
                  const myArray = property.split("_"); 
                  if (myArray[0] === 'group') {
                      for (const property_01 in this.fields_persona[property]) {
                          this.fields_persona[property][property_01][property_01] = ''
                      }
                  }
            }



        },
        handleClick(e) {
          this.locales.grupo_ano_escolar = []
          this.locales.grupo_ano_escolar.push(this.fields_evaluacion.[e]) 
        },     
        cerrar(msg) {
          console.log('guardar_plan_evaluacion: Actualizar')
          this.$emit("guardar_plan_evaluacion", msg);
        },    
        ocultar_pestana() {
          for (var property in this.pestana) {
                this.pestana[property] = 'hide'
            }
        },
        mostrar_pestana(obj) {
                this.pestana[obj] = 'show'
        },
        // Paso #2
      return_wizardPlanEvaluacion(msg) {    
            //this.buscarPersona('params')
            var guardar = JSON.parse(msg);

            const formData = new FormData()

        // desde aca optimizar
            this.mensaje.satisfactorio.status = false
            this.mensaje.nosatisfactorio.status = false

            //formData = JSON.parse(JSON.stringify(guardar.formData))

            formData.append('oper', 'REGISTRO')
            console.log('Guardar: ', this.send_datos)
            console.log('send_datos.plan_evaluacion: ', this.send_datos.plan_evaluacion)
            this.fields_evaluacion.group_datos_basicos.nu_id_institucion.nu_id_institucion = this.send_datos.plan_evaluacion.json_institucion.nu_id_institucion
            this.fields_evaluacion.group_datos_basicos.nu_id_ano_escolar.nu_id_ano_escolar = this.send_datos.plan_evaluacion.nu_id_ano_escolar
            this.fields_evaluacion.group_datos_basicos.nu_id_nivel_educativo.nu_id_nivel_educativo = this.send_datos.plan_evaluacion.json_nivel.nu_id_nivel_educativo
            this.fields_evaluacion.group_datos_basicos.nu_id_grado.nu_id_grado = this.send_datos.plan_evaluacion.nu_id_grado          
            this.fields_evaluacion.group_datos_basicos.nu_id_area_formacion.nu_id_area_formacion = this.send_datos.plan_evaluacion.nu_id_area_formacion
            formData.append('plan_evaluacion', JSON.stringify(this.fields_evaluacion))

            this.$store.dispatch(CREATE_PLAN_EVALUACION_WIZARD, formData).then(datos => { 
                  this.mensaje.satisfactorio.texto = "Registro creado  satisfactoriamente"
                  this.mensaje.satisfactorio.status = true
                  
                  setTimeout(() => {
                        this.isLoading = false
                    //    this.$emit("guardar_usuario", JSON.stringify(this.fields_institucion));
                        console.log('message emit from child component01: ', datos)
                        this.mensaje.satisfactorio.status = false
                        this.searchPlanEvaluacion('params')
                        this.$notify({
                          group: 'generic',
                          type: 'success',
                          title: 'Mensaje importante',
                          classes: 'w-full text-lg',
                          text: 'Datos  creado satisfactoriamente!'
                        });      

                    },3000)                  
                    
                    this.$emit("guardar_plan_evaluacion", JSON.stringify(guardar));              
                    
                    //this.$refs.tabs_ubigeo_tabJustify.click();

                  
              }, error => {
                  this.$store.commit(SET_ERROR, error.statusText)
                  this.mensaje.nosatisfactorio.texto = "Registro de prototipo cancelado"
                  this.mensaje.nosatisfactorio.status = true
                  this.ocultar_pestana()
                  this.mostrar_pestana('collapseMotivo')
                  setTimeout(() => {
                      this.mensaje.nosatisfactorio.status = false
                      },3000);             
                  console.log("Cancelar: " + guardar.btn_guardar);

              })  

          /// hasta aca optimizar


    },    


    },
    mounted() {
        var params = {
        nu_id_ano_escolar: this.send_datos.plan_evaluacion.nu_id_ano_escolar, 
        nu_id_institucion: this.currentUser.login_institucion.nu_id_institucion,
        nu_id_nivel_educativo: this.send_datos.plan_evaluacion.json_nivel.nu_id_nivel_educativo, 
        nu_id_grado: this.send_datos.plan_evaluacion.nu_id_grado, 
        nu_id_area_formacion: this.send_datos.plan_evaluacion.nu_id_area_formacion,               
        }
        this.searchPlanEvaluacion(params)

    },         

    
  }
</script>

<style lang="scss" scoped>

</style>