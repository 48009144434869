<template>
    <div>
        <div class="pl-2 pr-2">
            <div class="pl-4 pt-2 pr-2  mb-2 rounded-lg bg-skin-search text-black" >
              <v-text-field
                v-model="search"
                clearable
                :prepend-inner-icon=icons.mdiMagnify
                label="Buscador"
              ></v-text-field>
              </div>
              <div class="rounded-lg">

                  <v-data-table
                    :headers="headers"
                    :items="send_datos.list_instituciones"
                    :items-per-page="5"
                    :expanded.sync="expanded"   
                    item-key="nu_id_usuario"
                    show-expand                                     
                    :search="search"        
                    class="elevation-1"
                    :loading="isLoading"
                    loading-text="Cargando... Por favor espere"                    
                  >
                      <template v-slot:item.actions="{ item }">
                        <!--<v-icon
                          small
                          class="mr-2"
                          @click="indicadores_logro(item)"
                        >
                          {{ icons.mdiBookPlusMultiple }}
                        </v-icon>
                        -->
                        <v-icon
                          small
                          class="mr-2"
                          @click="editar(item)"
                        >
                          {{ icons.mdiPencil }}        
                        </v-icon>
        <!--                <v-icon
                          small
                          @click="mostrar_foto(getImgUrl(JSON.parse(item.tx_fotos_json)))"
                        >
                          {{ icons.mdiImageSearchOutline }}                
                        </v-icon>
        -->                
                      </template>
                        <!-- name -->
                        <template #[`item.tx_razon_social`]="{item}">
                          <div class="d-flex flex-column">
                            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.tx_razon_social }}</span>
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        <template #[`item.json_pais`]="{item}">
                          <div class="d-flex flex-column">
                                {{ JSON.parse(item.json_pais).tx_denominacion }}
            <!--                <small>{{ item.tx_nombres }}</small> -->
                          </div>
                        </template>          
                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                              <tr>
                                <td class="font-bold pr-3">
                                  Nombre comercial:
                                </td>
                                <td>
                                  {{ item.tx_nombre_comercial }}
                                </td>
                                </tr>
                                <tr>
                                  <td class="font-bold">
                                    Dirección:
                                  </td>
                                  <td>
                                    {{ item.tx_direccion }}
                                  </td>
                                </tr>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_ano_institucion_terminos)" :key="index">   
                                <span class="font-bold">Cantidad de estudiantes: </span>
                                  {{ item_01.tx_cantidad_estudiantes }}

                                </div>
                              <span class="font-bold">Contactos: </span>
                              <div class="" v-for="(item_01, index) in JSON.parse(item.json_ano_institucion_personal_contacto)" :key="index">   
                                      <span class="pl-9">
                                  <tr class="ml-9">
                                    <td>
                                        {{ item_01.tx_nombres }}
                                        {{ item_01.tx_apellidos }}
                                    </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ item_01.tx_telefono_celular }},
                                        {{ item_01.tx_telefono_local }}
                                      </td>
                                        
                                    </tr>
                                    <tr>
                                      <td>
                                        {{ item_01.tx_correo_electronico }}
                                      </td>
                                        
                                    </tr>
                                      </span>
                                </div>

                              <!--<div class="" v-for="(item_01, index) in JSON.parse(item.json_institucion_perfil)" :key="index">      
                                {{ item_01.tx_nombre_perfil }}
                              </div>
                              -->                            
                          </td>
                        </template>                                                  
                        
                  </v-data-table>
              </div>
    </div>

        <v-dialog
          max-width="800px"
          transition="dialog-bottom-transition"  
            v-if="dialogIndicadorLogro" :value="true" @input="dialogIndicadorLogro = false"                   
            >
            <v-card>
              <v-container>

                <div class="w-full mx-auto my-auto">

                <IndicadorLogroOpciones @guardar_indicador_logro="return_indicador_logro" :send_datos="send_datos" titulo="Indicadores de Logro"/>
                </div>

              </v-container>

            </v-card>
          </v-dialog>              

    </div>
</template>

<script>
import { LIST_INSTITUCION } from '@/store/actions.type'
import {  SET_ERROR } from '@/store/mutations.type'

import {
  mdiBookPlusMultiple,
  mdiMagnify,
  mdiPencil,
  mdiImageSearchOutline,
  mdiPlus

} from '@mdi/js'
import { mapGetters } from 'vuex'
import { reactive  } from "@vue/composition-api"
//import {   SET_ERROR } from '@/store/mutations.type'

import IndicadorLogroOpciones from "@/views/configuracion/IndicadoresLogro/IndicadorLogroOpciones";

export default {
    name: 'EvaluacionInstitucionListar',
    data() {
        return {
            dialogIndicadorLogro: false,
            dialogCompetencias: false,
            dialogPlanEvaluacion: false,
            dialog: false, 
            isLoading: true,
            search: '',
            expanded: [],            
            headers: [
              {
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'nu_id_institucion',
              },          
              {
                text: 'Razón Social',
                sortable: true,
                value: 'tx_razon_social',
              },          
              { 
                text: 'Ubicación', 
                value: 'json_pais' 
              },
              { 
                text: 'Acción', 
                value: 'actions', 
                sortable: false 
              },  
              { 
                text: '', 
                value: 'data-table-expand' 
              },                        

            ],            
        }
    },
    props: {
        send_datos: Object,

    },
    computed: {
        ...mapGetters(['fields_institucion']),
    },    
    setup() {
        const icons = reactive({
            mdiBookPlusMultiple,
            mdiMagnify,
            mdiPencil,
            mdiImageSearchOutline,
            mdiPlus
        });  

        return {
        icons,
        }
    },
    methods: {
      searchEvaluaciones(q) {
        this.isLoading = true
        this.$store.dispatch(LIST_INSTITUCION, {q: q}).then(response => {
            this.send_datos.list_instituciones = response.rest.items
        }, error => {
            this.$store.commit(SET_ERROR, error.statusText)
        }).finally(() => (
        setTimeout(() => {
            this.isLoading = false
          },3000)               
        //this.e1 = 1
        //this.limpiar_campos_clientes()
        ))      
      },
    indicadores_logro (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.institucion = id
      this.dialogIndicadorLogro = true
    },
    editar (id) {
      console.log('Editar id: ', id) // someValue
      this.send_datos.list_instituciones = id
      this.send_datos.operacion = 'EDITAR'
      this.dialog = true
    },
    return_indicador_logro(msg) {    
            if (!msg) {
              this.dialogIndicadorLogro = false
              return
            }
          /// hasta aca optimizar


    },    
    }, 
    components: {
      IndicadorLogroOpciones,
    },
    mounted() {
      this.searchEvaluaciones()
    },
    watch: {
    },    


}
</script>